import React from "react"
import { Link } from "gatsby"
import urlSlug from "url-slug"

import "./font-awesome"

import Layout from "./layout"
import SEO from "./seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons"

import defaultImage from "../images/slider-01.jpg"
import Pager from "./pager.js"

const eventList = ({ data, pageContext }) => (
  <Layout>
    <SEO title="Agenda - ICDA" />
    <section className="mb-3">
      <div className="container">
        <div className="my-5">
          <div className="d-flex align-items-center mb-3">
            <div className="flex-grow-1">
              <h5 className="text-uppercase">
                <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                Agenda <span className="font-weight-bold text-main">ICDA</span>
              </h5>
            </div>
          </div>
          {data.allStrapiDiaries.edges.length > 0 && (
            <div className="row">
              {data.allStrapiDiaries.edges.map(document => (
                <div
                  className="col-12 col-md-3 pb-4 mb-3"
                  key={document.node.id}
                >
                  <div className="d-flex flex-column h-100">
                    {document.node.imageResume?.image ? (
                      <div
                        className="diary-feed-img"
                        style={{
                          backgroundImage:
                            "url(" + document.node.imageResume.image.url + ")",
                        }}
                      ></div>
                    ) : (
                      <div
                        className="diary-feed-img"
                        style={{ backgroundImage: "url(" + defaultImage + ")" }}
                      ></div>
                    )}
                    <div className="bg-main px-2 py-1 text-white mb-2">
                      {document.node.diary_category?.name
                        ? document.node.diary_category.name
                        : "General"}
                    </div>
                    <h6 className="text-muted mb-0">{document.node.date}</h6>
                    <h6>
                      <small>{document.node.place}</small>
                    </h6>
                    <h5 className="mb-3">{document.node.title}</h5>
                    <Link
                      to={`/agenda/${urlSlug(document.node.title)}`}
                      className="btn btn-outline-primary w-auto mt-auto"
                    >
                      Ver más
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
          <Pager pageContext={pageContext} />
        </div>
      </div>
    </section>
  </Layout>
)

export default eventList
export const eventListQuery = graphql`
  query($skip: Int, $limit: Int) {
    allStrapiDiaries(
      filter: { academic_unit: { name: { eq: "ICDA" } } }
      skip: $skip
      limit: $limit
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          summary
          title
          place
          diary_category {
            name
          }
          cost
          content
          date(formatString: "DD MMMM, YYYY", locale: "es")
          headerImages {
            image {
              url
            }
          }
          imageResume {
            image {
              id
              url
            }
          }
        }
      }
    }
  }
`
